<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name Arabic"
              label-for="name-ar"
            >
              <b-form-input
                id="name-ar"
                v-model="certificateData.name_ar"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name Arabic"
                disabled
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Name English"
            rules="required"
          >
            <b-form-group
              label="Name English"
              label-for="name-en"
            >
              <b-form-input
                id="name-en"
                v-model="certificateData.name_en"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name English"
                disabled
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Description Arabic"
            rules="required"
          >
            <b-form-group
              label="Description Arabic"
              label-for="descriptionAr"
            >
              <b-form-input
                id="descriptionAr"
                v-model="certificateData.description_ar"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Description Arabic"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Description English"
            rules="required"
          >
            <b-form-group
              label="Description English"
              label-for="descriptionEn"
            >
              <b-form-input
                id="descriptionEn"
                v-model="certificateData.description_en"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Description English"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Process Type"
            rules="required"
          >
            <b-form-group
              label="Process Type"
              label-for="process_type"
            >
              <b-form-select
                id="process_type"
                v-model="certificateData.process_type"
                :options="optionsProcessType"
                trim
                placeholder="Process Type"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Proccess Time Days"
            rules="required"
          >
            <b-form-group
              label="Proccess Time Days"
              label-for="proccess_time_days"
            >
              <b-form-input
                id="proccess_time_days"
                v-model="certificateData.proccess_time_days"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Proccess Time Days"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Load"
            rules="required"
          >
            <b-form-group
              label="Load"
              label-for="load"
            >
              <b-form-input
                id="load"
                v-model="certificateData.load"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Load"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Expiration Duration Months"
            rules="required"
          >
            <b-form-group
              label="Expiration Duration Months"
              label-for="expiration_duration_months"
            >
              <b-form-input
                id="expiration_duration_months"
                v-model="certificateData.expiratino_duration_months"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Expiration Duration Months"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Department"
            rules="required"
          >
            <b-form-group
              label="Department"
              label-for="department_id"
            >
              <b-form-select
                id="department_id"
                v-model="certificateData.department_id"
                :options="optionsDepartments"
                trim
                placeholder="Department"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="FAQs"
            rules="required"
          >
            <b-form-group
              label="FAQs"
              label-for="file-faqs"
            >
              <div class="file-input-wrapper">
                <b-form-file
                  id="file-faqs"
                  multiple
                  @change="handleFileUpload($event, 'faqs')"
                />
                <div class="file-chips">
                  <b-badge
                    v-for="(file, index) in certificateData.faqs"
                    :key="index"
                    v-b-tooltip.hover.right
                    class="mr-2 d-flex align-items-center position-relative"
                    variant="info"
                    :title="file.name"
                    style="height: 28px;"
                  >
                    <span class="file-name py-2">{{
                      truncateFileName(file.name)
                    }}</span>
                    <button
                      style="width: 20px; height: 20px; border-radius: 50%; display: flex; align-items: center; justify-content: center; margin-left: 20px"
                      @click="removeFile('faqs', index)"
                    >
                      &times;
                    </button>
                  </b-badge>
                </div>
              </div>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Standard"
            rules="required"
          >
            <b-form-group
              label="Standard"
              label-for="file-standard"
            >
              <div class="file-input-wrapper">
                <b-form-file
                  id="file-standard"
                  multiple
                  @change="handleFileUpload($event, 'standard')"
                />
                <div class="file-chips">
                  <b-badge
                    v-for="(file, index) in certificateData.standard"
                    :key="index"
                    v-b-tooltip.hover.right
                    class="badge-item mr-2 d-flex align-items-center"
                    style="height: 28px;"
                    variant="info"
                    :title="file.name"
                  >
                    <span class="file-name py-2">{{
                      truncateFileName(file.name)
                    }}</span>
                    <button
                      style="width: 20px; height: 20px; border-radius: 50%; display: flex; align-items: center; justify-content: center; margin-left: 20px"
                      @click="removeFile('standard', index)"
                    >
                      &times;
                    </button>
                  </b-badge>
                </div>
              </div>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="FAQ Text EN"
            rules="required"
          >
            <b-form-group
              label="FAQ Text EN"
              label-for="faq_text_en"
            >
              <b-form-input
                id="faq_text_en"
                v-model="certificateData.faq_text_en"
                :state="getValidationState(validationContext)"
                trim
                type="text"
                placeholder="FAQ Text EN"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="FAQ Text AR"
            rules="required"
          >
            <b-form-group
              label="FAQ Text AR"
              label-for="faq_text_ar"
            >
              <b-form-input
                id="faq_text_ar"
                v-model="certificateData.faq_text_ar"
                :state="getValidationState(validationContext)"
                trim
                type="text"
                placeholder="FAQ Text AR"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="price"
            rules="required"
          >
            <b-form-group
              label="price"
              label-for="price"
            >
              <b-form-input
                id="price"
                v-model="certificateData.price"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="price"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="require_approve"
            rules="required"
          >
            <b-form-group
              label="require_approve"
              label-for="require_approve"
            >
              <b-form-select
                id="require_approve"
                v-model="certificateData.require_approve"
                :state="getValidationState(validationContext)"
                :options="optionRequireApprove"
                trim
                placeholder="require_approve"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="need_dataentry"
            rules="required"
          >
            <b-form-group
              label="Need Dataentry"
              label-for="Need Dataentry"
            >
              <b-form-select
                id="need_dataentry"
                v-model="certificateData.need_dataentry"
                :state="getValidationState(validationContext)"
                :options="optionNeedDataentry"
                trim
                placeholder="Need Dataentry"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="accountant_avg_time_in_hours"
            rules="required"
          >
            <b-form-group
              label="Accountant Avg Time In Hours"
              label-for="Accountant Avg Time In Hours"
            >
              <b-form-input
                id="accountant_avg_time_in_hours"
                v-model="certificateData.accountant_avg_time_in_hours"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Accountant Avg Time In Hours"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="first_reviewer_avg_time_in_hours"
            rules="required"
          >
            <b-form-group
              label="First Reviewer Avg Time In Hours"
              label-for="First Reviewer Avg Time In Hours"
            >
              <b-form-input
                id="first_reviewer_avg_time_in_hours"
                v-model="certificateData.first_reviewer_avg_time_in_hours"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="First Reviewer Avg Time In Hours"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="second_reviewer_avg_time_in_hours"
            rules="required"
          >
            <b-form-group
              label="Second Reviewer Avg Time In Hours"
              label-for="Second Reviewer Avg Time In Hours"
            >
              <b-form-input
                id="second_reviewer_avg_time_in_hours"
                v-model="certificateData.second_reviewer_avg_time_in_hours"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Second Reviewer Avg Time In Hours"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="data_entry_avg_time_in_hours"
            rules="required"
          >
            <b-form-group
              label="Data Entry Avg Time In Hours"
              label-for="Data Entry Avg Time In Hours"
            >
              <b-form-input
                id="data_entry_avg_time_in_hours"
                v-model="certificateData.data_entry_avg_time_in_hours"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                placeholder="Data Entry Avg Time In Hours"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="editCertificate"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addCertificate"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            /> Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      required,
      errors: {},
      loader: false,
      optionsDepartments: [],
      optionRequireApprove: [
        { value: 0, text: 'fasle' },
        { value: 1, text: 'true' },
      ],
      optionNeedDataentry: [
        { value: 0, text: 'fasle' },
        { value: 1, text: 'true' },
      ],
      optionsProcessType: [
        { value: 'audit', text: 'audit' },
        { value: 'inspection', text: 'inspection' },
        { value: 'null', text: 'study' },
      ],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const certificateData = ref({
      faqs: [],
      standard: [],
    })
    return {
      getValidationState,
      certificateData,
    }
  },
  mounted() {
    this.showCertificate()
    this.getDepartmentId()
  },
  methods: {
    getValidationState(validationContext) {
      return validationContext.errors[0] ? false : null
    },
    handleFileUpload(event, fieldName) {
      const { files } = event.target
      this.certificateData[fieldName] = [
        ...this.certificateData[fieldName],
        ...Array.from(files),
      ]
    },
    removeFile(fieldName, index) {
      this.certificateData[fieldName].splice(index, 1)
    },
    truncateFileName(fileName) {
      const words = fileName.split(' ')
      if (words.length > 3) {
        return `${words.slice(0, 3).join(' ')}...`
      }
      return fileName
    },
    getDepartmentId() {
      axios.get('departments').then(res => {
        this.optionsDepartments = decryptData(res.data.payload).data.departments.map(item => ({
          value: item.id,
          text: item.title_en,
        }))
      })
    },
    showCertificate() {
      if (this.$route.params.id) {
        axios
          .get(
            `certificate/${this.$route.params.MCR_id}/${this.$route.params.id}`,
          )
          .then(res => {
            this.certificateData = decryptData(res.data.payload).data.certificate
            this.certificateData.department_id = decryptData(res.data.payload).data.certificate.department.id
          })
      }
    },
    editCertificate() {
      this.loader = true
      const formData = new FormData()
      Object.keys(this.certificateData).forEach(key => {
        if (Array.isArray(this.certificateData[key])) {
          this.certificateData[key].forEach((file, index) => {
            formData.append(`${key}[${index}]`, file)
          })
        } else {
          formData.append(key, this.certificateData[key])
        }
      })
      formData.append('_method', 'PUT')
      axios
        .post(
          `certificate/${this.$route.params.MCR_id}/${this.$route.params.id}`,
          formData,
        )
        .then(res => {
          this.$router.push({ name: 'certificate' })
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                res.status === 200
                  ? 'Updated Successfully'
                  : 'Created Successfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        })
        .finally(() => {
          this.loader = false
        })
    },
    addCertificate() {
      this.loader = true
      const formData = new FormData()
      Object.keys(this.certificateData).forEach(key => {
        if (Array.isArray(this.certificateData[key])) {
          this.certificateData[key].forEach((file, index) => {
            formData.append(`${key}[${index}]`, file)
          })
        } else {
          formData.append(key, this.certificateData[key])
        }
      })
      formData.append('certificate_group_id', this.$route.params.MCR_id)
      axios
        .post(`certificate/${this.$route.params.MCR_id}`, formData)
        .then(res => {
          this.$router.push({ name: 'certificate' })
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                res.status === 200
                  ? 'Updated Successfully'
                  : 'Created Successfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'BellIcon',
              variant: 'error',
            },
          })
        })
        .finally(() => {
          this.loader = false
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.file-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.file-chips {
  display: flex;
  position: absolute;
  width: fit-content;
  max-width: 90%;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 1;
}

.file-chips::-webkit-scrollbar {
  height: 4px; /* Adjust height for the scrollbar */
}

.file-chips::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

.file-chips::-webkit-scrollbar-thumb {
  background: #3624dd; /* Color of the scrollbar handle */
  border-radius: 2px; /* Rounded corners for the scrollbar handle */
}

.file-chips::-webkit-scrollbar-thumb:hover {
  background: #4c3cd5; /* Color when hovering over the scrollbar handle */
}

.file-name {
  display: inline-block;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

// .file-tooltip {
//   display: none;
//   position: fixed;
//   bottom: 0px; /* Adjust this value to position the tooltip below the badge */
//   left: 0;
//   transform: translateX(-50%);
//   background: #333;
//   color: #fff;
//   padding: 5px 10px;
//   border-radius: 4px;
//   white-space: nowrap;
//   z-index: 2;
// }

// .badge-item:hover .file-tooltip {
//   display: block;
// }
</style>
